.boarding-banner {
  .title-box {
    max-width: 560px;
    padding-left: 60px;

    @media only screen and (max-width: 767px) {
      padding: 0 30px;
    }

    h1 {
      max-width: none;
    }
  }
}

.boarding-exp-sec {
  .outer-box {
    @extend .rounded-4;
    background-image: url(../../../assets/images/boarding-grooming-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;

    .form-box {
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.10);
      min-height: 300px;

      @include media-breakpoint-down(lg) {
        height: auto;
        min-height: 0;
      }

      .input-select,
      .form-select,
      .order-date-picker .p-calendar {
        background-color: #E3EDF4 !important;

        .p-inputtext {
          background-color: #E3EDF4 !important;
        }
      }
    }
  }
}

.cage-listing {
  margin-bottom: 150px;

  .cage-box {
    @extend .shadow-box;

    .card-figure {
      img {
        aspect-ratio: 4/3;
        @extend .w-100, .h-100;
        object-fit: cover;
      }
    }
  }
}

.cage-details {
  .cage-details-inner {
    .swiper-main-box {
      .swiper-slide {
        aspect-ratio: 1/1;

        img {
          @extend .rounded-3, .w-100, .h-100;
          object-fit: cover;
          cursor: pointer;
        }
      }

      .gallery-top {
        .swiper-slide {
          aspect-ratio: 4/3;

        }
      }

      .gallery-thumbs {
        .swiper-slide {
          aspect-ratio: 1/1;
        }
      }
    }
  }

  .cabin-detail-list {
    li {
      span.type-value {
        @extend .text-info;
        max-width: 320px;

        @include media-breakpoint-down(xl) {
          max-width: 240px;
        }

        @include media-breakpoint-down(lg) {
          max-width: 320px;
        }

        @include media-breakpoint-down(md) {
          max-width: none;
        }
      }
    }

    li:not(:last-child) {
      border-bottom: 1px dashed #A0C0D4;
      @extend .mb-3, .pb-3;
    }
  }

}

.boarding-sec {
  .bottom-strip {
    background-color: #F4F4F4;
    @extend .rounded-4, .p-4, .mt-5;
  }
}

.pet-selection-img-box {
  aspect-ratio: 4/2;
  object-fit: cover;
}

.pet-selection-form {


  .pet-info {


    li {
      width: calc((100% / 4) - 45px);
      border-right: 1px solid rgba(10, 143, 230, 0.20);
      margin-right: 45px;

      @include media-breakpoint-down(lg) {
        width: calc((100% / 2) - 8px);
        border: none;
        margin: 0;
      }

      &:nth-child(4),
      &:last-child {
        @extend .border-0;
      }
    }
  }
}