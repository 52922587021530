.hours-list {
  li {
    span {
      color: #6A93B0;
    }

    strong {
      @extend .text-white;
    }

    &:not(:last-child) {
      @extend .me-5;
    }
  }
}

.main-footer {
  @extend .bg-dark, .pt-5;
  margin-top: 150px;

  @include media-breakpoint-down(lg) {
    margin-top: 0;
  }

  hr {
    border-color: #283E4B;
  }

  .bird {
    left: 120px;
    top: -192px;
    @extend .d-lg-block, .d-none;
  }

  .dog {
    top: -134px;
    @extend .d-xl-block, .d-none;
  }

  .resource-list {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

    @include media-breakpoint-down(sm) {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }

    li {
      padding-left: 36px;
      @extend .mb-2;

      a {
        @extend .link-light;
      }

      &::before {
        content: '';
        background-image: url(../../images/footer/footer-list-icon.png);
        height: 20px;
        width: 20px;
        inset: 4px 0 0 0;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  .bottom-box {
    background-color: #18262F;
    border-radius: 24px;
  }

  .contact-details {
    span {
      @extend .text-uppercase;
    }

    .hours-list {

      a,
      span {
        font-size: 14px;
      }

      li {
        &:not(:last-child) {
          @extend .me-4;
        }
      }
    }
  }

  .social-details {
    background-color: #102029;
    border-radius: 24px;
    @extend .p-4;

    ul {
      @extend .d-flex;

      li {
        a {
          @extend .d-flex, .align-items-center, .justify-content-center;
          height: 40px;
          width: 40px;
          @extend .rounded-circle;
          background-color: #27363E;
          color: rgba(255, 255, 255, 0.6);
          transition: all 0.3s ease-out;

          &:hover {
            @extend .text-white, .bg-info;
          }
        }

        &:not(:last-child) {
          @extend .me-3;
        }
      }
    }
  }
}