.dynamic-health-map-body {
  min-height: 150vh;

  .link-upload {
    max-width: 300px;
    @extend .mt-3;

    .p-fileupload-choose {
      border: 1px dashed rgba(0, 13, 21, 0.20);
      @extend .bg-white, .d-flex, .align-items-center, .text-primary, .rounded-3;
      padding: 11px;

      .p-icon-wrapper {
        background-image: url(../../images/health-map/upload-cloud.svg);
        @extend .d-flex, .rounded-2, .me-3, .bg-default;
        height: 28px;
        width: 28px;
        aspect-ratio: 1/1;
        background-position: center;
        background-repeat: no-repeat;

        svg {
          @extend .d-none;
        }
      }
    }
  }

  .map-inner-box {
    .description-block {
      @extend .pb-4, ;

      p.description {
        font-size: 14px;
        @extend .mb-4;
      }

      &:not(:first-of-type) {
        border-top: 1px solid #D9EAF4;
        @extend .pt-4;
      }
    }
  }

  .slider-image {
    .uploaded-image-list {
      gap: 15px;

      &>div {
        width: 138px !important;
      }

      .break-text {
        @extend .w-100;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .fix-size-box {
      border-radius: 12px;
      border: 1px solid rgba(10, 143, 230, 0.40);
      @extend .bg-white, .p-2, .cursor-pointer, .position-relative, .overflow-hidden, .w-100;

      .icon-position {
        @extend .position-absolute, .top-0, .end-0, .d-none;

        a {
          @extend .m-1;

          &.fa-download {
            color: green;
          }

          &.fa-trash-can {
            color: red;
          }
        }
      }

      .img-box {
        aspect-ratio: 4/3;
        width: 100%;
        @extend .d-flex, .align-items-center, .justify-content-center;

        img {
          // @extend .w-100, .h-100;
          // object-fit: cover;
          max-height: 90px;
          width: auto;
          max-width: 100%;
        }
      }

      &:hover {
        .img-box {
          img {
            opacity: 0.25;
          }
        }

        .icon-position {
          @extend .d-flex;
        }
      }
    }
  }

  .ng-dropdown-panel-items {
    .form-check {
      input {
        @extend .form-check-input;
      }

      .item-label {
        @extend .form-check-label;
      }
    }
  }

  .service-block {

    .block-image {
      aspect-ratio: 4/3;
      @extend .overflow-hidden, .rounded-3, .position-relative;

      img {
        @extend .w-100, .h-100;
        object-fit: cover;
      }
    }

    .details.service-details {
      .heading-header.text-truncate {
        line-height: 1.5;
      }

      p:last-of-type {
        @extend .mb-0;
      }

      .fa-info-circle {
        @extend .text-success;
        font-size: 20px;
      }

      .heading {
        @extend .h5, .text-primary, .mt-3;
      }

      .card-price-health {
        span:not(.stike-price) {
          @extend .h5, .text-info;
        }

        .stike-price {
          @extend .text-decoration-line-through, .fw-bold;
        }
      }
    }

    .badge.badge-pill {
      @extend .text-uppercase;
      font-size: 12px;
      font-weight: 600;
      padding: 8px 16px;
      border-radius: 0 0 0 8px;
      @extend .position-absolute, .top-0, .end-0;

      &.custom-badge-low {
        @extend .bg-default;
      }

      &.custom-badge-high {
        background-color: #E73E33;
      }

      &.custom-badge-medium {
        @extend .bg-info;
      }
    }
  }

  .bwg-block {
    .out-box {
      @extend .position-absolute, .start-0;
      top: -55px;

      @include media-breakpoint-down(xl) {
        top: -50px;
      }

      @include media-breakpoint-down(md) {
        top: -45px;
      }
    }

    .service-block:not(:last-of-type) {
      @extend .mb-5, .mb-xl-0;
    }
  }

  .input-detail-content {
    .description-container {
      border-bottom: 1px solid rgba(10, 143, 230, 0.20);
      @extend .mb-3, .pb-3;
    }
  }

  .reportDetails.report-tab {
    @extend .position-relative;

    .download-btn {
      @extend .position-absolute;
      right: 25px;
      top: 25px;

      @include media-breakpoint-down(lg) {
        scale: 0.8;
        right: 20px;
        top: 20px;
      }

      @include media-breakpoint-down(md) {
        scale: 0.65;
      }
    }

    .report-details-box:first-of-type {
      .report-details-content>h4 {
        padding-right: 90px;
      }
    }
  }
}

.after-login {
  .generate-health-map {
    &::before {
      content: '';
      @extend .position-absolute;
      width: 2px;
      height: calc(100% - 95px);
      top: 24px;
      left: 24px;
      background-color: #D9EAF4;

      @include media-breakpoint-down(xl) {
        height: calc(100% - 90px);
      }

      @include media-breakpoint-down(lg) {
        top: 36px;
        height: calc(100% - 106px);
      }

      @include media-breakpoint-down(sm) {
        height: calc(100% - 146px);
      }
    }

    .individual-box {
      padding: 15px 15px 15px 45px;
      background-image: url(../../images/health-map/arrow-left.svg);
      background-position: left center;
      background-repeat: no-repeat;

      @include media-breakpoint-down(xl) {
        padding: 10px 10px 10px 30px;
      }

      @include media-breakpoint-down(lg) {
        padding: 10px 10px 30px 30px;
        background-position: left 14px;

        &:last-child {
          padding-bottom: 10px;
        }
      }

      @include media-breakpoint-down(md) {
        padding: 10px 10px 30px 25px;
      }

      @include media-breakpoint-down(sm) {
        background-image: none;
      }

      .left-sec {
        @extend .bg-white, .mb-3, .mb-lg-0;
        background-image: url(../../images/health-map/arrow-right.svg);
        background-position: right center;
        background-repeat: no-repeat;

        @include media-breakpoint-down(lg) {
          background: none;
        }

        .img-box {
          @include media-breakpoint-down(xxl) {
            width: 100px;
            height: 100px;
          }
        }

        h6 {
          @extend .bg-white, .pe-0, .ps-xl-0, .ps-md-1, .ps-2, .pe-sm-3;
        }
      }

      .right-sec {
        @extend .bg-white, .rounded-4;
        border: 1px solid #D9EAF4;
        box-shadow: 0px 4px 16px 0px rgba(198, 232, 255, 0.50);

        .outer-box {
          max-height: 310px;
          height: fit-content;
          overflow-y: auto;
          overflow-x: hidden;

          @include media-breakpoint-down(lg) {
            max-height: none;
          }
        }

        .btn-success {
          font-size: 12px;
          padding: 10px 20px;
        }

        .inner-box {
          @extend .rounded-3, .p-3;

          .img-box {
            min-width: 56px;
            width: 56px;
            height: 56px;
          }

          .content-box {
            .small {
              font-size: 12px;
            }

            .text-truncate {
              max-width: 250px;

              @include media-breakpoint-down(xxl) {
                max-width: 168px;
              }

              @include media-breakpoint-down(lg) {
                max-width: 250px;
              }

              @include media-breakpoint-down(sm) {
                max-width: none;
                text-overflow: unset;
                white-space: unset;
              }
            }

            @media only screen and (max-width: 1399px) {


              .text-info {
                font-size: 14px;
              }

              del {
                font-size: 12px;
              }

              .small {
                font-size: 10px;
              }
            }


          }

          &:not(:last-child) {
            @extend .mb-2;
          }
        }
      }

      &::before {
        content: '';
        @extend .position-absolute, .rounded-circle;
        left: -12px;
        outline-style: solid;
        outline-color: #DCE1E3;
        outline-width: 2px;
        border: 5px solid #fff;
        width: 24px;
        height: 24px;
        top: 0;
        bottom: 0;
        margin: auto 0;

        @include media-breakpoint-down(lg) {
          bottom: auto;
          margin: 0;
          top: 10px;
        }

        @include media-breakpoint-down(sm) {
          left: 9px;
          width: 16px;
          height: 16px;
          border: 4px solid #fff;
          top: 12px;
        }
      }

      &:nth-child(odd) {
        &::before {
          background-color: #0BC484;
        }

        h6 {
          color: #0BC484;
        }

        .right-sec {
          .inner-box {
            border: 1px solid #65D6AC;
            background: #E9FFF7;
          }
        }
      }

      &:nth-child(even) {
        &::before {
          background-color: #D3A604;
        }

        h6 {
          color: #D3A604;
        }

        .right-sec {
          .inner-box {
            border: 0.729px solid #E9D28D;
            background: #FEFAEA;
          }
        }
      }
    }
  }
}