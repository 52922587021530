.wizard {
    .nav-tabs {
        gap: 10px;
        overflow-y: auto;

        li {
            width: calc(100% / 3);

            .nav-link {
                @extend .d-flex, .align-items-center, .justify-content-between, .border-0, .p-md-4, .p-3, .flex-column, .flex-md-row, .h-100;
                background-color: #D2E2EC;
                border-radius: 0;
                height: 100px;

                .count {
                    height: 40px;
                    width: 40px;
                    aspect-ratio: 1/1;
                    @extend .rounded-circle, .d-flex, .align-items-center, .justify-content-center, .text-white;
                    background-color: rgba($color: $default, $alpha: 0.3);
                    font-weight: 600;
                }

                &.active,
                &.completed {
                    @extend .text-white;

                    .count {
                        background-color: rgba($color: #fff, $alpha: 0.2);
                    }

                    img {
                        filter: brightness(0) invert(1);
                    }
                }

                &.active {
                    @extend .bg-info;
                }

                &.completed {
                    @extend .bg-secondary;
                }
            }

            &:first-child {
                .nav-link {
                    border-radius: 24px 0 0 24px;
                }
            }

            &:last-child {
                .nav-link {
                    border-radius: 0 24px 24px 0;
                }
            }
        }
    }

    .title-box {
        max-width: 900px;
        @extend .mx-auto;
    }

    .camp-box {
        @extend .rounded-3, .h-100, .px-4, .pt-4, .position-relative, .default-box, .overflow-hidden;
        padding-bottom: 110px;

        .ribbon {
            @extend .text-white, .position-absolute;
            background-color: #0bc484;
            width: 180px;
            height: 40px;
            transform: rotate(45deg);
            right: -50px;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            font-weight: 500;
        }

        &.planSelected {
            @extend .bg-primary, .border-primary;

            li {
                @extend .text-white;
            }
        }

        .camp-head {
            @extend .bg-white, .p-4, .rounded-3;

            .camp-desc {
                word-wrap: break-word;
                line-height: 1.5;

                span.title {
                    white-space: nowrap;
                    padding-right: 10px;
                    width: fit-content;
                    max-width: calc(100% - 40px);
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .camp-price {
                .badge {
                    @extend .rounded-pill, .bg-secondary;
                    font-size: 16px;
                }
            }
        }

        .camp-listpoint {
            li {
                border-bottom: 1px solid rgba($color: #0A8FE6, $alpha: 0.2);
                @extend .pb-2, .mt-3;


                .icon-box {
                    height: 20px;
                    width: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    aspect-ratio: 1/1;
                    font-size: 14px;
                    @extend .text-white, .rounded-circle, .d-flex, .justify-content-center, .align-items-center;

                    &.red {
                        background-color: #F44336;
                    }

                    &.green {
                        background-color: #00BA00;
                    }
                }
            }

        }

        .camp-bottom {
            @extend .position-absolute;
            inset: auto 0 24px 0;
            margin: 0 auto;
            width: 256px;
            cursor: pointer;
        }
    }

    .camp-listing {
        .camp-plan-box {
            @extend .shadow-box;

            .plan-title {
                @extend .h5;
                font-weight: 600 !important;
            }

            .plan-price {
                @extend .my-2;

                span {
                    @extend .text-info, .h4;
                }
            }

        }
    }

    .pet-form-box {
        @extend .overflow-hidden, .rounded-5, .bg-light;

        .tob-box {
            border-radius: 36px 36px 0 0;

            h3 {
                @extend .text-primary;
            }

            border: 1px solid rgba(10, 143, 230, 0.40);

            .select-box {
                @include media-breakpoint-up(lg) {
                    width: 400px;
                }
            }
        }

        .bottom-box {
            h3 {
                span {
                    @extend .text-secondary;
                }
            }

            .content-box {
                @extend .bg-primary, .overflow-hidden, .text-white;

                .shadow-img {
                    @extend .position-absolute;
                    bottom: -128px;
                    left: 0;

                    @include media-breakpoint-down(xxl) {
                        bottom: -110px;
                    }

                    @include media-breakpoint-down(xl) {
                        bottom: -90px;
                    }

                    @include media-breakpoint-down(lg) {
                        bottom: -93px;
                        width: 300px;
                        right: 0;
                        margin: auto;
                    }

                }
            }
        }
    }


    .pet-form-box .bottom-box .content-box {
        p {
            margin-bottom: 150px;
        }
    }
}