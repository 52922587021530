.home-banner {
  height: calc(100vh - 150px);
  background-image: url(../../../assets/images/home/banner-bg.jpg);
  background-size: cover;
  min-height: 600px;

  h1 {
    max-width: 600px;
  }

  @include media-breakpoint-down(xl) {
    height: auto;
    padding: 60px 0;
    min-height: 0;

    h1 {
      max-width: none;
    }

    .banner-img {
      width: 500px;
    }
  }


}

.book-appointment {
  background-image: url("../../images/home/bookApp-bg.png");
  background-position: center;
  background-size: cover;
  padding: 90px 0;

  @include media-breakpoint-down(md) {
    padding: 60px 0;
  }

  .book-appointment-form {
    max-width: 900px;
    @extend .bg-light;
    border-radius: 24px;
  }
}

.contact-form {
  padding: 90px 0 0 0;
  @extend .position-relative, .bg-white;
  z-index: 0;

  @include media-breakpoint-down(lg) {
    padding: 90px 0 60px 0;
  }

  .bg-dog {
    top: -182px;
  }

  &::after {
    content: '';
    @extend .position-absolute, .w-100, .h-50, .start-0, .top-0, .bg-info;
    z-index: -1;
  }

  .container {
    box-shadow: 0px 4px 32px 0px rgba(187, 187, 187, 0.50);
    @extend .bg-white, .rounded-4, .p-md-5, .py-3;

    .seperator {
      width: calc(100% - 22px);
      @extend .mx-auto;
      border-color: $default;
    }

    .title-box {
      p {
        max-width: 300px;

        @include media-breakpoint-down(lg) {
          max-width: none;
        }
      }
    }

    .loc-box {
      &>h4 {
        background-size: 28px;

        @include media-breakpoint-down(xl) {
          background-size: 24px;
        }

        @include media-breakpoint-down(md) {
          background-size: 20px;
        }
      }

      a {
        @extend .text-info, .h6;
      }

      .sub-title {
        &+span {
          @extend .h6;
        }
      }

      @include media-breakpoint-down(xl) {
        min-height: 377px;
      }
    }

    .form-box {
      @extend .bg-light;
    }

    .left-box {
      background-color: #63BEDD;
    }
  }
}