.custom-plan-list {
    .custom-plan-box {
        .badge {
            font-size: 12px;
            background-color: #31C5DD;
            border-radius: 0 0 0 10px !important;
            padding: 6px 12px !important;
        }
    }
}

.date-grooming-inner {
    @extend .rounded-3, .py-5, .default-box;

    @include media-breakpoint-down(lg) {
        height: 600px;
    }

    @include media-breakpoint-down(md) {
        height: 500px;
    }

    @include media-breakpoint-down(sm) {
        height: 300px;
    }

    .custom-calendar {
        // width: 800px;

        @include media-breakpoint-down(lg) {
            scale: 0.8;
            height: 600px;
        }

        @include media-breakpoint-down(md) {
            scale: 0.75;
        }

        @include media-breakpoint-down(sm) {
            scale: 0.6;
        }

        .p-calendar {
            @extend .bg-transparent, .p-0, .rounded-0;

            .p-datepicker {
                @extend .bg-transparent, .p-0, .border-0;

                .p-datepicker-header {
                    @extend .bg-transparent, .p-0, .border-0, .mx-auto, .mb-4;
                    max-width: 400px;

                    .p-datepicker-next,
                    .p-datepicker-prev {
                        @extend .rounded-circle, .bg-white;

                        &::before {
                            font-family: FontAwesome;
                            @extend .text-default;
                        }

                        &:hover {
                            &::before {
                                @extend .text-info;
                            }
                        }

                        .p-icon-wrapper {
                            @extend .d-none;
                        }
                    }

                    .p-datepicker-next {
                        &::before {
                            content: '\f061';
                        }
                    }

                    .p-datepicker-prev {
                        &::before {
                            content: '\f060';
                        }
                    }

                }

                .p-datepicker-calendar-container {
                    .p-datepicker-calendar {
                        @extend .m-0;
                    }

                    @extend .rounded-3, .bg-white, .overflow-hidden;

                    th {
                        @extend .text-white, .text-center, .bg-default;
                        padding: 14px;
                    }

                    td {
                        // font-size: 20px;

                        &>span {
                            width: 60px;
                            height: 60px;
                            aspect-ratio: 1/1;
                            @extend .d-flex, .justify-content-center, .align-items-center, .rounded-4;
                        }

                        span.p-highlight {
                            @extend .bg-info;
                        }
                    }
                }
            }
        }
    }
}

.plan-inner-box {
    &.selected {
        border-color: $secondary;
        background-image: url(../../images/selected.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 16px) 16px;
    }

    .img-box {
        @extend .rounded-4, .overflow-hidden, .me-3;
        height: 64px;
        width: 64px;

        img {
            @extend .w-100, .h-100;
            object-fit: cover;
        }
    }

    .avail-day {
        @extend .mt-2;

        li {
            @extend .me-1, .rounded-pill, .py-1, .text-center, .text-capitalize;
            background-color: #DAE9F3;
            font-size: 12px;
            font-weight: 600;
            width: 50px;

        }

        &.date-list {
            li {
                width: 60px;
            }
        }
    }
}

.order-accordion-inner {
    @extend .rounded-3, .p-md-5, .default-box, .p-4;

    &:not(.tab-pane) {
        @extend .p-3, .p-md-5;
    }

    .box-modal {
        border: 1px dashed #0A8FE6;
        @extend .bg-white, .p-4, .rounded-4;

        &.custom-summary-block {
            @extend .text-primary;

            .type-list {
                li {
                    .type-value {
                        font-size: 18px;
                    }

                    &:not(:last-child) {
                        @extend .mb-4;
                    }
                }
            }
        }

        &.pay-block {
            .type-list-main {
                &>li {
                    @extend .pb-3, .mb-3;
                    border-bottom: 1px dashed rgba(82, 103, 117, 0.40);

                    .type-name {
                        font-size: 18px;
                        font-weight: 500;
                    }
                }

                .multi-type-title {
                    &>.type-name {
                        font-size: 18px;
                        font-weight: 500;
                    }
                }

                .multiple-type-list {
                    @extend .pb-3, .mb-3;
                    border-bottom: 1px dashed rgba(82, 103, 117, 0.40);

                }
            }

            .groomer-tip-box {
                @extend .rounded-4;
                background: #F6F6F6;

                .select-tip-list {
                    li {
                        border: 1px solid #D9EAF4;
                        @extend .rounded-pill, .py-1, .px-3, .mb-2, .me-2, .cursor-pointer, .bg-white;

                        &.selected-tip-item {
                            @extend .text-white, .bg-secondary;
                            font-weight: 600;
                        }
                    }

                }
            }

            .advanced-form {
                .form-checkbox {
                    @extend .rounded-4;
                    background: #F6F6F6;
                }

                .form-control {
                    @extend .mb-3, .input-bg;
                }
            }
        }
    }
}

.modal-body {
    .order-accordion-inner {
        @extend .pb-3, .px-0, .pt-0;
    }
}

.camp-plan-box,
.pet-detail-form,
.addpetdetails,
.grooming-modal-vaccination .vaccination-modal {
    .form-check-input {
        pointer-events: none;
    }
}

.vaccination-modal {

    .vaccination-box {
        .img-box {
            width: 64px;
            height: 64px;
            min-width: 64px;

            @include media-breakpoint-down(md) {
                width: 50px;
                height: 50px;
                min-width: 50px;
            }

            img {
                @extend .w-100, .h-100;
                object-fit: cover;
            }
        }

        @include media-breakpoint-down(md) {
            h6 {
                font-size: 14px;
            }
        }
    }
}