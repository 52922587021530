.body-overflow {
  overflow: hidden;
}

.main-header {
  @extend .position-relative;
  z-index: 9;

  .location-dropdown {
    background-color: rgba($color: #fff, $alpha: 0.1);
    @extend .rounded-pill;
    background-repeat: no-repeat;
    background-position: 12px 15px;
    padding: 10px 10px 10px 36px;
    width: 300px;
    font-size: 14px;
    background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.75 7.5C15.75 12.75 9 17.25 9 17.25C9 17.25 2.25 12.75 2.25 7.5C2.25 5.70979 2.96116 3.9929 4.22703 2.72703C5.4929 1.46116 7.20979 0.75 9 0.75C10.7902 0.75 12.5071 1.46116 13.773 2.72703C15.0388 3.9929 15.75 5.70979 15.75 7.5Z" stroke="%23ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 9.75C10.2426 9.75 11.25 8.74264 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.74264 7.75736 9.75 9 9.75Z" stroke="%23ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');

    .input-class {
      @extend .border-0, .p-0, .text-white;
      background-color: transparent;
      font-size: 14px;

      &:focus {
        box-shadow: none;
      }
    }

    .icon-box {
      width: 24px;
      height: 24px;
      aspect-ratio: 1/1;
      @extend .rounded-circle, .d-flex, .justify-content-center, .align-items-center, .text-white;
      background-color: #2A5573;
    }
  }

  .account-list {
    &.no-login {
      margin: 30px 0 !important;

      @include media-breakpoint-down(md) {
        margin: 0 0 30px 0 !important;
      }
    }

    &>li {


      a {
        @extend .text-white, .text-uppercase;
      }

      &:nth-child(2) {
        &::before {
          content: '';
          background-color: #6A93B0;
          width: 2px;
          height: 24px;
          inset: 0 auto auto -16px;
        }
      }
    }

    .profile-btn {
      background: rgba(255, 255, 255, 0.10);
      @extend .rounded-0, .text-white, .border-0;
      padding: 15px;
      font-size: 14px;

      &::after {
        @extend .d-none;
      }

    }

    .dropdown-menu {
      inset: 0 0 auto auto !important;
      @extend .border-0, .px-0, .pt-0;
      box-shadow: 0px 30px 32px 0px rgba(0, 0, 0, 0.16);
      padding-bottom: 0;

      ul {
        transition: all 0.2s ease-in;
        @extend .py-md-2;

        li {
          .dropdown-item {
            @extend .py-2, .position-relative;
            padding-right: 60px;
            font-weight: 500;

            .fa-solid {
              opacity: 0;
              @extend .position-absolute, .top-0, .bottom-0, .my-auto;
              right: 15px;
              height: 16px;
              width: 16px;
            }

            .img-box {
              background-color: #E8F3FA;
              height: 42px;
              width: 42px;
            }

            &:hover,
            &:focus {
              @extend .bg-transparent, .text-info;

              .fa-solid {
                opacity: 1;
              }

              .img-box {
                @extend .bg-info;

                img {
                  filter: brightness(0) invert(1);
                }
              }
            }

          }
        }
      }



      @include media-breakpoint-down(md) {
        inset: 17px -79px auto auto !important;
        width: 100vw;
        height: calc(100vh - 165px);
        overflow-y: auto;
        padding-bottom: 80px;
      }

    }


  }

  .bottom-strip {
    border-bottom: 1px solid #CED6DB;
    box-shadow: 0px 4px 16px 0px #DFE5EA;

    &.sticky-header {
      position: fixed;
      top: 0px;
      left: 0;
      width: 100%;
      @extend .bg-white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 1000;
    }

    .brand {
      img {
        max-height: 40px;
        width: auto;
        height: 100%;
      }
    }


    #megaMenuWrapper {
      ul.mega-menu {
        gap: 25px;

        @include media-breakpoint-down(lg) {
          gap: 0;
        }

        li.manin-item-list {


          &:hover {
            @extend .text-secondary;
          }

          a.mega-main-title {
            color: #5D6C76;
            @extend .position-relative, .d-block;
            padding-top: 30px;
            padding-bottom: 30px;
          }

          &.with-submenu {
            .main-sub-menu {
              @extend .bg-white, .d-none, .position-absolute, .d-flex;
              overflow-y: auto;
              box-shadow: 0px 30px 32px 0px rgba(0, 0, 0, 0.16);
              width: 800px;
              height: 360px;
              padding: 16px 20px 16px 16px;
              left: calc(50vw - 400px);

              .tab-link-list,
              .tab-content-list {
                width: 50%;
              }

              .tab-content-list {
                background-color: #FBFFE4;
                position: sticky;
                top: 0;
                overflow: hidden;
              }

              font-weight: 14px;

              .tablinks {
                &:not(:last-child) {
                  @extend .mb-2;
                }

                a {
                  @extend .py-2, .ps-2;

                  .plan-icon {
                    background-color: #F4F4F4;
                    @extend .me-3, .rounded-circle;
                    height: 32px;
                    width: 32px;
                    padding: 6px;
                  }

                  &:hover {
                    @extend .bg-secondary;
                    border-radius: 100px 0 0 100px;

                    .plan-icon {
                      @extend .bg-white;
                    }
                  }
                }

                &.active-menu-item {
                  a {
                    @extend .bg-secondary;
                    border-radius: 100px 0 0 100px;

                    .plan-icon {
                      @extend .bg-white;
                    }
                  }
                }
              }

              .tabcontent {
                @extend .d-none, .p-4, .text-dark;

                &.active-menu-content {
                  @extend .d-block;
                }

                p {
                  font-size: 14px;
                }

                .inner-title {
                  font-weight: 600;
                  @extend .mb-1, .text-secondary;
                }

                .inner-link {
                  cursor: pointer;
                  @extend .py-1;

                  &:hover {
                    @extend .text-secondary;
                  }
                }
              }
            }

            a.mega-main-title {
              padding-right: 22px;

              &::before {
                content: '';
                @extend .position-absolute, .start-0, .w-100, .bg-secondary, .bottom-0;
                height: 2px;
                visibility: hidden;
                transition: all 0.3s ease-out;
              }

              &::after {
                content: '\f107';
                font-family: FontAwesome;
                @extend .ps-1, .position-absolute, .end-0, .top-0, .bottom-0, .my-auto;
                height: 20px;
                width: 20px;
                transition: all .3s ease;
              }
            }

            &:hover {
              .main-sub-menu {
                @extend .d-flex;
              }
            }
          }

        }
      }

      @include media-breakpoint-down(lg) {
        display: none;
        position: absolute;
        width: 100%;
        background-color: #fff;
        top: 158px;
        left: 0;
        height: calc(100vh - 158px);
        padding: 15px;

        ul.mega-menu li.manin-item-list {
          &:hover {
            &::before {
              display: none;
            }
          }
        }

        ul.mega-menu li.manin-item-list a.mega-main-title {
          padding: 10px 0;
          border-bottom: 1px solid #f4f4f4;
        }
      }
    }

    .cart {
      margin-right: 15px;

      &>.btn {
        background-color: rgba($color: #BAD1E0, $alpha: 0.32);
        aspect-ratio: 1/1;
        @extend .p-2, .d-flex, .justify-content-center, .align-items-center, .border-0;

        &:active {
          @extend .border-0;
        }
      }

      .dropdown-toggle::after {
        @extend .d-none;
      }

      .cart-list {
        width: 360px;
        @extend .bg-white, .p-4, .border-0;
        inset: 0px 0px auto auto !important;
        box-shadow: 0px 30px 32px 0px rgba(0, 0, 0, 0.16);

        @include media-breakpoint-down(md) {
          width: 100vw;
          height: calc(100vh - 165px);
          right: -26px !important;
          top: 17px !important;
        }

        .title-box {
          @extend .p-2, .bg-light, .text-info, .rounded-3;
          border: 1px solid rgba(10, 143, 230, 0.20);
          font-size: 13px;
        }

        .cart-product-list {
          max-height: 400px;
          overflow-y: auto;
          overflow-x: hidden;
          padding: 15px 0;

          @include media-breakpoint-down(sm) {
            max-height: calc(100vh - 380px);
          }

          .dropdown-item {
            border-bottom: 1px solid rgba(0, 0, 0, 0.10);
            @extend .pb-3;

            &:hover,
            &:active {
              @extend .bg-white;
            }

          }

          .img-box {
            min-width: 64px;
            height: 64px;
            aspect-ratio: 1/1;
            @extend .rounded-3, .overflow-hidden;

            img {
              @extend .w-100, .h-100;
              object-fit: cover;
            }
          }

          .content-box {
            width: 230px;
            font-size: 14px;
          }



          .btn-box {
            a {
              width: 32px;
              @extend .rounded-circle, .p-2, .ms-2;
              height: 32px;
              background-color: #f4f4f4;
            }
          }
        }

        h6 {
          font-weight: 600;
        }

      }
    }
  }

  .hours-list {
    li {
      span:first-of-type {
        font-size: 12px;
      }
    }
  }
}

#outpostModal {

  .modal-header {
    padding: 40px 40px 10px 40px;
    @extend .border-0;
  }

  .detect-loc {
    background-color: #f3f3f3;
  }

  .map-btn {
    @extend .text-secondary;
    font-weight: 500;
  }

  .detect-loc-btn {
    background-color: #31C5DD;
    padding: 10px 20px;
    @extend .text-white, .d-flex, .align-items-center, .rounded-3;
  }

  .modal-body {
    padding: 0 40px 40px 40px;

  }
}

.loc-box {
  border-radius: 24px;
  border: 2px solid #D9EAF4;
  @extend .bg-white, .p-4, .shadow-sm;

  &>h6,
  &>h4 {
    padding: 0 30px 0 0;
    line-height: 1.5;
    background-repeat: no-repeat;
    background-position: right 4px;
    background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.75 7.5C15.75 12.75 9 17.25 9 17.25C9 17.25 2.25 12.75 2.25 7.5C2.25 5.70979 2.96116 3.9929 4.22703 2.72703C5.4929 1.46116 7.20979 0.75 9 0.75C10.7902 0.75 12.5071 1.46116 13.773 2.72703C15.0388 3.9929 15.75 5.70979 15.75 7.5Z" stroke="%236F6C86" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 9.75C10.2426 9.75 11.25 8.74264 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.74264 7.75736 9.75 9 9.75Z" stroke="%236F6C86" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }

  .sub-title {
    @extend .text-uppercase;
    font-weight: 500;

    &+span {
      @extend .text-info;
      font-weight: 600;
    }
  }
}

.profile-img {
  display: block;
  width: 36px;
  height: 36px;

  .avatar-content {
    font-size: 14px !important;
  }
}

.nav-mobile-menu {
  position: absolute;
  z-index: 9;
  width: 100%;
  background-color: #fff;
  top: 139px;
  height: calc(100vh - 139px);
  overflow-y: auto;
  left: 0;
  padding-bottom: 80px;

  @include media-breakpoint-down(md) {
    top: 165px;
    height: calc(100vh - 165px);
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    background: unset;
  }

  .mat-accordion {
    background-color: #fff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);

    .mat-expansion-panel:first-of-type,
    .mat-expansion-panel:last-of-type {
      border-radius: 0;
    }

    &>.mat-expansion-panel {
      &:hover {
        background: none;
      }

      .mat-expansion-panel-header-title {
        border-bottom: 1px solid #eaeaea;
      }
    }

    .mat-expansion-panel-header {
      height: auto;
      padding: 0;

      .mat-expansion-panel-header-title {
        margin: 0px 15px;
        padding: 10px 0;
        font-size: 16px;
      }

      &:hover {
        background-color: transparent;
      }

      &.mat-expanded {
        height: auto;

        span.mat-content {
          .mat-expansion-panel-header-title {
            border-bottom: none;
            @extend .text-secondary;
          }
        }

        .mat-expansion-indicator::after {
          @extend .text-secondary;
        }
      }
    }

    .mat-expansion-panel {
      box-shadow: none;
      border-radius: 0;
    }

    .mat-expansion-panel-spacing {
      margin: 0;
    }

    .mat-expansion-panel-content {
      background-color: #FBFFE2;

      .mat-expansion-panel-body {
        .mat-accordion {
          .mat-expansion-panel {
            .mat-expansion-panel-header-title {
              font-size: 14px;
              @extend .text-primary;
            }

            &:not(:last-child) {
              .mat-expansion-panel-header-title {
                @extend .border-bottom, .border-success;
              }
            }

            &:last-child {
              .mat-expansion-panel-header-title {
                border-bottom: 1px solid transparent;
              }
            }
          }
        }
      }

      .mat-expansion-panel-header {
        background-color: #FBFFE2;
        position: relative;
        z-index: 0;

        &.mat-expanded {


          .mat-expansion-panel-header-title {
            border-bottom: 1px solid transparent !important;
          }
        }
      }

      .mat-expansion-panel-content {
        line-height: 23px;
        margin: 0 15px;
        @extend .border-bottom, .border-success;
      }
    }

    .mat-expansion-indicator {
      position: absolute;
      right: 30px;
    }

    .mat-expansion-panel-body {
      padding: 0;
    }

    .plan-icon-width {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      color: #fff;
      background-color: #fff;
      font-size: 16px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #EAF1BC;
      padding: 6px;
    }

    .product-img-width {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      font-size: 16px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon-min-width {
      height: 32px;
      width: 32px;
      margin-right: 12px;
    }
  }

  .mat-accordion .mat-expansion-panel-body .plan-content {
    padding: 0;
    margin: 5px 0;
    cursor: pointer;
    @extend .text-primary;
    text-decoration: underline;
  }

  .mat-accordion .mat-expansion-panel-content .mat-expansion-panel-body .mat-accordion .mat-expansion-panel.level-2 .mat-expansion-panel-header.mat-expanded .mat-expansion-panel-header-title {
    @extend .border-bottom-0, .text-secondary;
  }

  .level-2 {
    .mat-expansion-panel-content .mat-expansion-panel-body {
      margin: 0 0 15px 0;
      background-color: #EDF2CF;
      padding: 10px;
      @extend .rounded-3;
    }
  }
}

.not-logged-in {
  .nav-mobile-menu {
    top: 216px;
    height: calc(100vh - 216px);

    @include media-breakpoint-up(md) {
      top: 156px;
      height: calc(100vh - 156px);
    }
  }
}

.sticky-header {
  .nav-mobile-menu {
    top: 73px;
    height: calc(100vh - 73px);
  }

  .account-list .dropdown-menu {
    height: calc(100vh - 73px);
    padding-bottom: 0;
  }

  .main-header .bottom-strip .cart .cart-list {
    height: calc(100vh - 73px);
  }
}

.mobile-menu {
  font-size: 24px;
  @extend .text-primary, .me-3, .d-flex, .justify-content-center, .align-items-center;
  width: 30px;
  height: 30px;
  cursor: pointer;

  .hamburger .line {
    width: 24px;
    height: 3px;
    @extend .bg-primary;
    display: block;
    margin: 4px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &.mobile-menu-open {
    .hamburger .line:nth-child(1) {
      -webkit-transform: translateY(7px) rotate(45deg);
      -ms-transform: translateY(7px) rotate(45deg);
      -o-transform: translateY(7px) rotate(45deg);
      transform: translateY(7px) rotate(45deg);
    }

    .hamburger .line:nth-child(2) {
      opacity: 0;
    }

    .hamburger .line:nth-child(3) {
      -webkit-transform: translateY(-7px) rotate(-45deg);
      -ms-transform: translateY(-7px) rotate(-45deg);
      -o-transform: translateY(-7px) rotate(-45deg);
      transform: translateY(-7px) rotate(-45deg);
    }
  }
}

.main-header .bottom-strip #megaMenuWrapper ul.mega-menu li.manin-item-list.with-submenu {
  @extend .position-static;

  &:hover {
    a.mega-main-title {
      &::before {
        visibility: visible !important;
      }

      &::after {
        transform: rotate(-180deg);
      }
    }
  }
}