.swiper-button-prev,
.swiper-button-next {
  aspect-ratio: 1/1;
  z-index: 8;
  @extend .rounded-circle;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-family: FontAwesome !important;
  @extend .h-100, .w-100, .d-flex, .justify-content-center, .align-items-center;
}

.swiper-button-next::after {
  content: '\f061' !important;
}

.swiper-button-prev::after {
  content: '\f060' !important;
}

//home page
.pet-plan {
  background-image: url(../../../assets/images/home/pet-plan-bg.png);
  background-position: center;
  background-size: contain;
  padding: 90px 0;
  background-color: $primary;
  @extend .text-white;
  position: relative;
  background-repeat: no-repeat;

  @include media-breakpoint-down(xl) {
    padding: 75px 0;
  }

  @include media-breakpoint-down(md) {
    padding: 60px 0;
  }

  .swiper-slide {
    opacity: 0 !important;
  }

  .swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-active {
    opacity: 1 !important;
  }

  .bg-dog {
    bottom: -8px;
    height: 300px;
    z-index: 2;

    @include media-breakpoint-down(xxl) {
      height: 160px;
      bottom: -6px;
    }

  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: $info;
    height: 500px;
    width: calc(50vw + 90px);
    border-radius: 24px 0 0 0;
    @extend .d-lg-block, .d-none;

    @include media-breakpoint-down(xxl) {
      height: 400px;
      width: 50vw;
    }

    @include media-breakpoint-down(xl) {
      height: 400px;
      width: 48vw;
    }
  }

  .contain-box {
    margin: 0 120px 0 0;
    height: calc(100% - 100px);

    @include media-breakpoint-down(xl) {
      height: 100%;
      margin: 0 60px 0 0;
    }

    @include media-breakpoint-down(lg) {
      margin: 0;
    }
  }

  .img-box {


    img {
      aspect-ratio: 1/1;
      object-fit: cover;
      width: 100%;
      height: 100%;
      @extend .my-5, .my-lg-0;

      @include media-breakpoint-down(lg) {
        aspect-ratio: 4/3;
        max-height: 350px;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: #1B3F57;
    height: 64px;
    width: 64px;

    @include media-breakpoint-down(xl) {
      scale: 0.75;
      top: 15px;
    }

    &::after,
    &::before {
      font-size: 24px;
      @extend .text-white;
    }
  }

  .swiper-button-prev {
    left: calc(100% - 150px);

    @include media-breakpoint-down(lg) {
      left: calc(100% - 140px);
    }
  }
}

.client-say {
  padding: 90px 0;
  background-image: url(../../images/home/client-bg.png);
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  @extend .overflow-hidden;

  @include media-breakpoint-down(md) {
    padding: 60px 0 150px 0;
  }

  .swiper-wrapper {
    padding: 60px 0;
  }

  .swiper-container {
    margin: 0 80px;

    @include media-breakpoint-up(sm) {
      overflow: hidden;
    }

    @include media-breakpoint-down(lg) {
      margin: 0 75px;
    }

    @include media-breakpoint-down(md) {
      margin: 0;
    }
  }

  .swiper-wrapper {
    @extend .d-flex, .align-items-center;
  }

  .swiper-slide {
    @extend .rounded-3, .bg-white;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.16);
    width: 500px;

    @include media-breakpoint-down(xxl) {
      width: 400px;
    }

    @include media-breakpoint-down(lg) {
      width: 360px;
    }

    @include media-breakpoint-down(md) {
      width: 320px;
    }

    &:not(.swiper-slide-visible) {
      opacity: 0;
    }

    .swiper-slide-visible {
      opacity: 1;
    }
  }

  .user-box {
    .img-box {

      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
      border: 4px solid #fff;

      &::after {
        @extend .position-absolute, .top-0, .start-0, .translate-middle;
        content: '';
        width: 20px;
        height: 20px;
        background-image: url(../../images/home/quote.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% auto;
      }

      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: #E3F4FF;
    border: 2px solid $info;
    height: 40px;
    width: 40px;
    top: 44px;
    bottom: 0;
    margin: auto 0;

    &::after,
    &::before {
      font-size: 16px;
      color: $info;
    }

    @include media-breakpoint-down(md) {
      top: auto;
      bottom: 15px;

      &.swiper-button-prev {
        left: calc(50% - 48px);
      }

      &.swiper-button-next {
        right: calc(50% - 48px);
      }
    }
  }


}