.error {
    font-size: 14px;
}

.my-order-inner-box {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        color: $default;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
        color: $secondary;
        background-color: #F7FAEA;

        span.ng-option-label {
            font-weight: 500;
        }
    }
}

.pet-selection {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {

        input[type='checkbox'] {
            @extend .me-2;
            accent-color: $info;
        }
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        background-color: #e3edf4;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
        background-color: rgb(10, 143, 230);
        color: #fff;
    }
}

.loc-box-popover {
    .sub-title {
        @extend .text-uppercase;
        color: $default;
        font-weight: 500;

        &+span {
            @extend .text-info;
            font-weight: 600;
        }
    }

    &>h6 {
        font-size: 18px;
        padding: 0 30px 0 0;
        background-repeat: no-repeat;
        background-position: right 4px;
        background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.75 7.5C15.75 12.75 9 17.25 9 17.25C9 17.25 2.25 12.75 2.25 7.5C2.25 5.70979 2.96116 3.9929 4.22703 2.72703C5.4929 1.46116 7.20979 0.75 9 0.75C10.7902 0.75 12.5071 1.46116 13.773 2.72703C15.0388 3.9929 15.75 5.70979 15.75 7.5Z" stroke="%236F6C86" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 9.75C10.2426 9.75 11.25 8.74264 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.74264 7.75736 9.75 9 9.75Z" stroke="%236F6C86" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
}

.modal-body {
    .wizard {
        .nav-tabs {
            gap: 5px;

            li .nav-link {
                padding: 16px !important;

                .count {
                    height: 36px;
                    width: 36px;
                    font-size: 14px;
                }

                h6 {
                    font-size: 16px;
                }
            }
        }
    }
}

.recent-order-list {
    .groom-box:not(:last-of-type) {
        &::after {
            content: '';
            height: 1px;
            width: calc(100% - 48px);
            background-color: #D9EAF4;
            @extend .position-absolute, .bottom-0, .start-50, .translate-middle;
        }
    }
}

.custom-btn-update {
    width: fit-content;
}

img.avatar-content {
    object-fit: cover;
}

.modal-body {
    .cart-btn {
        .btn {
            font-size: 12px;
            padding: 8px 16px;
        }
    }
}

.tab-view {
    gap: 14px;

    .nav-item {
        width: calc(50% - 7px);

        .nav-link {
            background-color: #D2E2EC;
            padding: 14px;

            &.active {
                @extend .bg-info;
            }
        }

        &:first-child {
            .nav-link {

                border-radius: 16px 0 0 16px;
            }
        }

        &:last-child {
            .nav-link {

                border-radius: 0 16px 16px 0;
            }
        }
    }

}

.avatar-container {
    @extend .w-100, .h-100;

    .avatar-content {
        @extend .rounded-0, .w-100, .h-100, .d-flex, .justify-content-center, .align-items-center;
        line-height: 1 !important;
        font-weight: 400 !important;
        font-family: "Poppins", sans-serif !important;
    }
}

.pet-detail-profile-icon {
    display: block;
    aspect-ratio: 1/1;

    .avatar-content {
        font-size: 80px !important;
    }
}

.ng-dropdown-panel,
.popover,
.p-datepicker,
.ng-select.ng-select-opened .ng-select-container {
    z-index: 8 !important;

    @include media-breakpoint-down(sm) {

        scale: 0.9;
    }
}

.modal-p-datepicker {
    z-index: 9999 !important;
}

.btn-close {
    &:focus {
        @extend .shadow-none;
    }
}

.auto-fit-box {
    @media only screen and (max-width: 991px) {
        flex-wrap: wrap;

        &>div {
            width: 25%;

            &:nth-child(4) {
                margin-bottom: 10px;
            }
        }
    }

    @media only screen and (max-width: 991px) {
        flex-wrap: wrap;

        &>div {
            width: 25%;

            &:nth-child(4) {
                margin-bottom: 10px;
            }
        }
    }

    @media only screen and (max-width: 575px) {
        &>div {
            width: 50%;

            &:nth-child(even) {
                margin-bottom: 10px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

    }
}

.modal-text-truncate {
    max-width: 240px;

    @include media-breakpoint-down(lg) {
        max-width: none;
        text-overflow: unset;
        white-space: unset;
        max-width: none;
    }
}

.form-checkbox.form-check.my-3.py-3.px-sm-5 {
    @include media-breakpoint-down(sm) {
        padding: 8px 36px;

        .form-check-label {
            font-size: 12px;
        }
    }
}

.btn {
    .ladda-spinner {
        &>div {
            margin-top: -10px;
        }
    }
}

.swal2-actions,
.swal2-content {
    @include media-breakpoint-down(md) {
        padding: 0 !important;

        .swal2-styled {
            font-size: 14px !important;
        }
    }
}

#boardingPlanModal,
#groomingPlanModal {
    .modal-content {
        height: 100vh;
    }
}

.main-sub-menu::-webkit-scrollbar,
.cart-product-list::-webkit-scrollbar,
.ng-dropdown-panel-items::-webkit-scrollbar {
    width: 12px;
    background-color: #e9eef4 !important;
}

.main-sub-menu::-webkit-scrollbar-track,
.cart-product-list::-webkit-scrollbar-track,
.ng-dropdown-panel-items::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e9eef4 !important;
    width: 12px;
}

.main-sub-menu::-webkit-scrollbar-thumb,
.cart-product-list::-webkit-scrollbar-thumb,
.ng-dropdown-panel-items::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #0a8fe680 !important;
    width: 12px;
    border: 3px solid #e9eef4;
}



.main-sub-menu::-webkit-scrollbar-thumb:vertical:hover,
.cart-product-list::-webkit-scrollbar-thumb:vertical:hover,
.ng-dropdown-panel-items::-webkit-scrollbar-thumb:vertical:hover {
    background-color: #74b1da !important;
}


@-moz-document url-prefix() {

    .main-sub-menu,
    .cart-product-list,
    .ng-dropdown-panel-items {
        scrollbar-width: auto;
        scrollbar-color: #0a8fe680 #e9eef4;
    }
}