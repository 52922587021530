.form-inner-box {
    width: 400px;

    @include media-breakpoint-down(md) {
        max-width: 400px;
        width: 100%;
    }

    h4 {
        &::before {
            content: '';
            @extend .position-absolute, .bg-info;
            width: 40px;
            height: 2px;
            inset: 0 auto auto 0;
        }
    }

    .lined-box {
        background-image: url(../../../assets/images/line-left-bg.svg), url(../../../assets/images/line-right-bg.svg);
        background-position: left center, right center;
        background-repeat: no-repeat;
        font-size: 14px;
        font-weight: 500;
        margin: 60px 0 0 0;

        a {
            text-decoration: underline;
        }

        @include media-breakpoint-down(sm) {
            background: none;
        }
    }

    .link-txt {
        font-weight: 500;
    }

    .error {
        @extend .position-relative, .d-block;
        top: -10px;
        line-height: 16px;
    }
}

.login-banner {
    background-image: url(../../../assets/images/login/login-banner.png);
    background-size: cover;
}

.login-container {
    .left-sec {
        overflow: hidden;

        .inner-box {
            background-image: url(../../../assets/images/login/login-left-bg.png);
            border-radius: 24px 0 0 24px;
            background-size: cover;
            background-position: 0 258px;
            overflow: hidden;

            @media only screen and (max-width: 991px) {
                border-radius: 24px 24px 0 0;
            }

            @media only screen and (max-width: 575px) {
                background-position: 0 196px;
            }
        }

        .top-box {
            background-color: $primary;

            @media only screen and (max-width: 575px) {
                img {
                    max-width: 280px;
                    width: 100%;
                }
            }
        }



        ul {
            list-style: none;

            li {
                @extend .text-white;
                padding: 15px 0 15px 36px;

                &::before {
                    content: '\f058';
                    font-size: 20px;
                    line-height: 22px;
                    color: $success;
                    inset: 16px auto auto 0;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                }
            }
        }
    }

    .right-sec {
        .form-box {
            background-image: url(../../../assets/images/login/login-right-bg.png);
            background-size: cover;
            background-position: center;
            border-radius: 0 24px 24px 0;

            @media only screen and (max-width: 991px) {
                border-radius: 24px;
            }
        }

    }
}

.form-control:disabled {
    @extend .bg-white;
}